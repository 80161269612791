import api from '../../services/api';
import React, { useEffect, useState } from 'react';
import Modal from 'react-modal'; // Install this package if not already installed

Modal.setAppElement('#root'); // Replace '#root' with your app's root element ID

const Billing = () => {
  const [payments, setPayments] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  // Modal state
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedPayment, setSelectedPayment] = useState(null);
  const [cancellationDate, setCancellationDate] = useState(null);

  useEffect(() => {
    const fetchPayments = async () => {
      try {
        const response = await api.get('/billing/', {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
          },
        });

        if (response.status !== 200) {
          throw new Error(response.data.error || 'Failed to fetch payments.');
        }

        setPayments(response.data.data);
      } catch (err) {
        setError(err.response?.data?.error || err.message || 'An unexpected error occurred.');
      } finally {
        setLoading(false);
      }
    };

    fetchPayments();
  }, []);

  const openModal = (payment) => {
    setSelectedPayment(payment);
    // Calculate cancellation date as two months from today
    const effectiveDate = new Date();
    effectiveDate.setMonth(effectiveDate.getMonth() + 2);
    setCancellationDate(effectiveDate.toLocaleDateString('en-US', { month: 'long', day: 'numeric', year: 'numeric' }));
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedPayment(null);
    setCancellationDate(null);
  };

  const handleCancelSubscription = async () => {
    if (!selectedPayment) return;

    try {
      const response = await api.post('/cancel-subscription/', {
        subscription_id: selectedPayment.id,
      }, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      });

      if (response.status === 200) {
        // Optionally, update the UI to reflect cancellation
        setPayments(payments.map(payment => 
          payment.id === selectedPayment.id ? { ...payment, status: 'canceled' } : payment
        ));
        closeModal();
      } else {
        throw new Error(response.data.error || 'Failed to cancel subscription.');
      }
    } catch (err) {
      setError(err.response?.data?.error || err.message || 'An unexpected error occurred.');
      closeModal();
    }
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div style={{ color: 'red' }}>Error: {error}</div>;

  return (
    <div>
      <h2>Your Past Payments</h2>
      {payments.length === 0 ? (
        <p>No payments found.</p>
      ) : (
        <table style={{ width: '100%', borderCollapse: 'collapse' }}>
          <thead>
            <tr>
              <th style={tableHeaderStyle}>Date</th>
              <th style={tableHeaderStyle}>Amount</th>
              <th style={tableHeaderStyle}>Status</th>
              <th style={tableHeaderStyle}>Product</th>
              <th style={tableHeaderStyle}>Subscription Duration</th>
              <th style={tableHeaderStyle}>Action</th> {/* New Column */}
            </tr>
          </thead>
          <tbody>
            {payments.map((payment) => (
              <tr key={payment.id}>
                <td style={tableCellStyle}>
                  {new Date(payment.created * 1000).toLocaleDateString()}
                </td>
                <td style={tableCellStyle}>
                  {new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: payment.currency.toUpperCase(),
                  }).format(payment.amount / 100)}
                </td>
                <td style={tableCellStyle}>{capitalizeFirstLetter(payment.status)}</td>
                <td style={tableCellStyle}>{payment.product_ids || 'N/A'}</td>
                <td style={tableCellStyle}>{capitalizeFirstLetter(payment.subscription_duration) || 'N/A'}</td>
                <td style={tableCellStyle}>
                  {payment.status !== 'canceled' && payment.status !== 'inactive' ? (
                    <button onClick={() => openModal(payment)} style={cancelButtonStyle}>
                      Cancel
                    </button>
                  ) : (
                    '—'
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}

      {/* Modal */}
      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        contentLabel="Cancel Subscription"
        style={customModalStyles}
      >
        <h2>Cancel Subscription</h2>
        <p>
          Please note that based on our <strong>Terms of Services</strong>, the cancellation request should be submitted at least <strong>40 days</strong> before.
        </p>
        <p>Your effective date of cancellation will be <strong>{cancellationDate}</strong>.</p>
        <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '20px' }}>
          <button onClick={closeModal} style={modalButtonStyle}>Close</button>
          <button onClick={handleCancelSubscription} style={{ ...modalButtonStyle, marginLeft: '10px', backgroundColor: '#d9534f', color: '#fff' }}>
            Cancel Subscription
          </button>
        </div>
      </Modal>
    </div>
  );
};

// Helper function to capitalize first letter
const capitalizeFirstLetter = (string) => {
  if (!string) return '';
  return string.charAt(0).toUpperCase() + string.slice(1);
};

// Basic styles for table
const tableHeaderStyle = {
  borderBottom: '2px solid #ddd',
  textAlign: 'left',
  padding: '8px',
};

const tableCellStyle = {
  borderBottom: '1px solid #ddd',
  textAlign: 'left',
  padding: '8px',
};

// Styles for Cancel button
const cancelButtonStyle = {
  padding: '6px 12px',
  backgroundColor: '#d9534f',
  color: '#fff',
  border: 'none',
  borderRadius: '4px',
  cursor: 'pointer',
};

// Modal custom styles
const customModalStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '400px',
    padding: '20px',
  },
};

// Styles for modal buttons
const modalButtonStyle = {
  padding: '6px 12px',
  border: 'none',
  borderRadius: '4px',
  cursor: 'pointer',
  backgroundColor: '#ccc',
};

export default Billing;
