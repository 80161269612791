import React, { useState } from 'react';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
  ResponsiveContainer,
} from 'recharts';
import { styles } from './styles';
import { renderMinMax } from './utils';

import { getBordersForTitle, getQualityLabel } from './utils';

const Card = ({
  icon,
  title,
  value,
  unit,
  subtitle,
  chartData,
  tooltipText,
  minMax,
}) => {
  const [hoveredValue, setHoveredValue] = useState(null);
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [popupContent, setPopupContent] = useState(null);

  const handleMouseMove = (state) => {
    if (state && state.activePayload && state.activePayload.length) {
      setHoveredValue(state.activePayload[0].value);
    } else {
      setHoveredValue(null);
    }
  };

  const handleDataPointClick = (data, index) => {
    // Customize the content as needed
    const content = (
      <div>
        <h4>{title} Details</h4>
        <p>Date: {data.date}</p>
        <p>
          Value: {data.value} {unit}
        </p>
        {/* Add more detailed information here */}
      </div>
    );
    setPopupContent(content);
    setIsPopupVisible(true);
  };

  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      setHoveredValue(payload[0].value);
    } else {
      setHoveredValue(null);
    }
    return null;
  };

  const borders = getBordersForTitle(title);

  const xAxisDomain =
    borders.length > 0
      ? [
          Math.min(...borders.map((border) => border.value)) -
            0.05 * Math.min(...borders.map((border) => border.value)),
          Math.max(...borders.map((border) => border.value)) +
            0.05 * Math.max(...borders.map((border) => border.value)),
        ]
      : [0, 10];

  const qualityLabel = getQualityLabel(hoveredValue, borders);

  // Intervals for top labels (areas between borders)
  const intervals = [];
  for (let i = 0; i < borders.length - 1; i++) {
    const start = borders[i].value;
    const end = borders[i + 1].value;
    const midpoint = (start + end) / 2;
    intervals.push({ label: borders[i].label, x: midpoint });
  }
  if (borders.length > 0) {
    const lastBorder = borders[borders.length - 1].value;
    const end = xAxisDomain[1];
    const midpoint = (lastBorder + end) / 2;
    intervals.push({ label: borders[borders.length - 1].label, x: midpoint });
  }

  return (
    <div className='card'>
      <div className='card-body'>
        <h5 className='card-title d-flex align-items-center'>
          {icon && <img src={icon} alt={title || 'Icon'} style={styles.icon} />}
          <span>{title}</span>
          {tooltipText && (
            <span
              style={styles.tooltipIcon}
              onClick={() => {
                setPopupContent(
                  <div>
                    <h4>{title} Information</h4>
                    <p>{tooltipText}</p>
                  </div>
                );
                setIsPopupVisible(true);
              }}
              role='button'
              tabIndex={0}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  setPopupContent(
                    <div>
                      <h4>{title} Information</h4>
                      <p>{tooltipText}</p>
                    </div>
                  );
                  setIsPopupVisible(true);
                }
              }}
            >
              <i className='fa-solid fa-circle-question blue-icon'></i>
            </span>
          )}
        </h5>

        <h2 className='text-primary'>
          {hoveredValue !== null ? (
            <>
              {hoveredValue}
              {unit}{' '}
              <span
                style={{ marginLeft: '5px', color: 'black', fontSize: '15px' }}
              >
                {qualityLabel || ''}
              </span>
            </>
          ) : (
            <>
              {value} {unit}
            </>
          )}
        </h2>

        {minMax ? (
          ['Dhea', 'Igf 1'].includes(title) ? (
            renderMinMax(minMax, unit)
          ) : (null)
        ) : null}

        <hr style={{ border: '1px solid #ccc', margin: '10px 0' }} />
        {subtitle && <p className='text-secondary'>{subtitle}</p>}
        <div style={styles.chartContainer}>
          {chartData && chartData.length > 0 ? (
            <ResponsiveContainer width='100%'>
              <LineChart
                data={chartData}
                margin={{ left: -30, right: 10, top: 10, bottom: 10 }}
                onMouseMove={handleMouseMove}
                onMouseLeave={() => setHoveredValue(null)}
              >
                <CartesianGrid stroke='#ccc' />
                <XAxis
                  dataKey='date'
                  axisLine={{ stroke: '#ddd' }}
                  tick={{ fontSize: 11 }}
                  padding={{ left: 10, right: 10 }}
                />
                <YAxis
                  axisLine={{ stroke: '#ddd' }}
                  tick={{ fontSize: 11 }}
                  padding={{ top: 10, bottom: 10 }}
                />
                <Tooltip content={<CustomTooltip />} />
                <Line
                  type='monotone'
                  dataKey='value'
                  stroke='#8884d8'
                  dot={{
                    stroke: '#0000FF',
                    strokeWidth: 2,
                    onClick: (data, index) => handleDataPointClick(data, index),
                    cursor: 'pointer',
                  }}
                />
              </LineChart>
            </ResponsiveContainer>
          ) : (
            <p style={{ textAlign: 'center', color: '#888' }}>
              No data available
            </p>
          )}
        </div>
      </div>

      {/* Popup Modal */}
      {isPopupVisible && (
        <div
          style={styles.popupOverlay}
          onClick={() => setIsPopupVisible(false)}
        >
          <div style={styles.popupContent} onClick={(e) => e.stopPropagation()}>
            <button
              style={styles.closeButton}
              onClick={() => setIsPopupVisible(false)}
            >
              &times;
            </button>
            {popupContent}
          </div>
        </div>
      )}
    </div>
  );
};

Card.defaultProps = {
  icon: null,
  title: 'Untitled',
  value: 'N/A',
  unit: '',
  subtitle: '',
  chartData: [],
  tooltipText: '',
  minMax: null,
};

export default Card;
