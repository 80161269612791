// Helper function to format metric keys for display
export const formatTitle = (key) => {
  const titles = {
    body_fat_percentage: "Body Fat Percentage",
    lean_mass: "Lean Mass",
    total_mass: "Total Mass",
    visceral_fat: "Visceral Fat",
    body_fat_lbs: "Body Fat (lbs)",
    t_score: "T-Score",
    bone_mineral_content: "Bone Mineral Content",
  };
  return (
    titles[key] ||
    key.replace(/_/g, " ").replace(/\b\w/g, (c) => c.toUpperCase())
  );
};

// Helper function to get the latest non-null value for a metric
export const getLatestValue = (metricData) => {
  if (!Array.isArray(metricData) || metricData.length === 0) return null;

  // Iterate through the array in reverse to find the latest non-null value
  for (let i = metricData.length - 1; i >= 0; i--) {
    const value = metricData[i].value;
    if (value !== null && value !== undefined) {
      return value;
    }
  }

  // If all values are null or undefined, return null
  return null;
};

// Helper function to define units based on the metric
export const getUnit = (key) => {
  const units = {
    body_fat_percentage: " %",
    lean_mass: " lbs",
    total_mass: " lbs",
    visceral_fat: " lbs",
    body_fat_lbs: " lbs",
    t_score: "",
    bone_mineral_content: " lbs",

    // Lab Results
    a1c: " %",
    fasting_insulin: " uIU/mL",
    estradiol: " pg/ml",
    dhea: " ug/dL",
    glucose: " mg/dL",
    igf_1: " ng/mL",
    total_cholesterol: " mg/dL",
    hdl: " mg/dl",
    ldl: " mg/dl",
    triglycerides: " mg/dl",
    total_testosterone: " mg/dL",
    free_testosterone: " pg/mL",
    dhea_s: " pg/dL",
  };
  return units[key] || "";
};

export const getIcon = (key) => {
  const basePath = `${window.location.origin}/body_compositions_icon/`;
  const icons = {
    body_fat_percentage: `${basePath}body_fat_percentage.svg`,
    lean_mass: `${basePath}lean_mass.svg`,
    total_mass: `${basePath}total_mass.svg`,
    visceral_fat: `${basePath}visceral_fat.svg`,
    body_fat_lbs: `${basePath}body_fat_lbs.svg`,
    t_score: `${basePath}t_score.svg`,
    bone_mineral_content: `${basePath}bone_mineral_content.svg`,
  };
  return icons[key] || "";
};

export const getSubtitles = (key) => {
  const subtitles = {
    body_fat_percentage: "Your Body Fat Trend Over Time",
    lean_mass: "Your Lean Mass Trend Over Time",
    total_mass: "Your Total Mass Trend Over Time",
    visceral_fat: "Your Visceral Fat Trend Over Time",
    body_fat_lbs: "Your Body Fat Trend Over Time",
    t_score: "Your T-Score Trend Over Time",
    bone_mineral_content: "Your Bone Mineral Content Trend Over Time",
  };
  return subtitles[key] || "";
};

export const getLabIcon = (key) => {
  const basePath = `${window.location.origin}/`;
  const icons = {
    a1c: `${basePath}default_lab_result.svg`,
    fasting_insulin: `${basePath}default_lab_result.svg`,
    estradiol: `${basePath}default_lab_result.svg`,
    dhea: `${basePath}default_lab_result.svg`,
    glucose: `${basePath}default_lab_result.svg`,
    igf_1: `${basePath}default_lab_result.svg`,
    total_cholesterol: `${basePath}default_lab_result.svg`,
    hdl: `${basePath}default_lab_result.svg`,
    ldl: `${basePath}default_lab_result.svg`,
    triglycerides: `${basePath}default_lab_result.svg`,
    total_testosterone: `${basePath}default_lab_result.svg`,
    free_testosterone: `${basePath}default_lab_result.svg`,
    dhea_s: `${basePath}default_lab_result.svg`,
  };
  return icons[key] || "";
};

export const getMinMax = (key) => {
  const minMaxData = {
    a1c: { low: "<4.0%", high: ">6.4%" },
    fasting_insulin: { low: "2", high: "20" },
    estradiol: { normal: "10-40" },
    dhea: {
      "20-29 years": "280-640",
      "30-39 years": "120-520",
      "40-49 years": "95-400",
      "50+ years": "70-310",
    },
    glucose: { low: "70", high: "125" },
    igf_1: {
      "20-29": "116-358",
      "30-39": "115-307",
      "40-49": "106-264",
      "50+": "98-246",
    },
  };
  return minMaxData[key] || {};
};

export const getLabResultTooltipText = (key) => {
  const tooltips = {
    a1c: "A1C reflects your average blood sugar level over the past three months.",
    fasting_insulin: "Fasting insulin levels help assess insulin resistance.",
    estradiol:
      "Estradiol is a form of estrogen critical for reproductive health.",
    dhea: "DHEA is a hormone involved in producing other hormones, like testosterone and estrogen.",
    glucose:
      "Glucose levels indicate your blood sugar levels, used to screen for diabetes.",
    igf_1:
      "IGF-1 is a growth hormone marker reflecting metabolic and growth processes.",
  };
  return tooltips[key] || "No additional information available.";
};

export const getBodyCompositionTooltipText = (key) => {
  const tooltips = {
    body_fat_percentage:
      "Body fat percentage is the proportion of fat in your body.",
    lean_mass: "Lean mass is the weight of your body minus all fat mass.",
    total_mass: "Total mass is the sum of lean mass, fat mass, and bone mass.",
    visceral_fat: "Visceral fat is the fat stored around your organs.",
    body_fat_lbs: "Body fat in pounds is the total weight of fat in your body.",
    t_score: "T-score is a measure of bone density compared to a young adult.",
    bone_mineral_content:
      "Bone mineral content is the total mineral content in your bones.",
  };
  return tooltips[key] || "No additional information available.";
};

export const getLabSubtitles = (key) => {
  const subtitles = {
    a1c: "Your A1C Trend Over Time",
    fasting_insulin: "Your Fasting Insulin Trend Over Time",
    estradiol: "Your Estradiol Trend Over Time",
    dhea: "Your DHEA Trend Over Time",
    glucose: "Your Glucose Trend Over Time",
    igf_1: "Your IGF-1 Trend Over Time",
    total_cholesterol: "Your Total Cholesterol Trend Over Time",
    hdl: "Your HDL Trend Over Time",
    ldl: "Your LDL Trend Over Time",
    triglycerides: "Your Triglycerides Trend Over Time",
    total_testosterone: "Your Total Testosterone Trend Over Time",
    free_testosterone: "Your Free Testosterone Trend Over Time",
    dhea_s: "Your DHEA-S Trend Over Time",
  };
  return subtitles[key] || "";
};
